/*global App : true*/

define([
    "expose-loader?exposes=$!jquery",
    "underscore",
    "backbone",
    "router",
    "local-router",
    "components/modal",
    "components/modal-question",
    "components/noti",
    "components/header",
    "components/cookie-noticer",
    "utils/analytics",
    "i18next/i18next",
    "models/user",
    "components/yii-js-router",
    "backbone-nested",
    "backbone.overrides"
    // "imports?jQuery=jquery!lib/jquery.lazyload/jquery.lazyload"
    ], function($, _, Backbone, Router, LocalRouter, Modal, ModalQuestion, Noti, HeaderView, CookieNoticer, Analytics, i18n, UserModel, YiiJsRouter) {
        "use strict";
        var $body = $("body");

        App.YiiJsRouter = new YiiJsRouter();
        App.urlTo = function(path, params) {
            return App.YiiJsRouter.generateUrl(path, params);
        };
        App.urlRegexTo = function(path, params) {
            return App.YiiJsRouter.generateRegexUrl(path, params);
        };

        App.LocalRouter = new LocalRouter();
        App.Router = new Router();
        App.Modal = new Modal();
        App.ModalQuestion = new ModalQuestion();
        App.Modals = {};
        App.CookieNoticer = new CookieNoticer();
        App.Header = new HeaderView();
        App.Events = _.extend({}, Backbone.Events);
        App.User = new UserModel({
            html: $(".js-user")
        }, { parse: true });
        App.Noti = new Noti();

        Backbone.history.start({
            hashChange: false,
            pushState: true,
            root: "/"
        });

        _.each($(".js-app-flash"), function(flash) {
            var $alert = $(flash);
            App.Noti.show($alert.text(), $alert.attr("data-type"));
        });

        $body.removeClass("is-loading no-js").addClass("js-loaded");

        // Google analitycs track events
        $("[data-gacategory]").on("click", function(e) {
            var $el = $(e.currentTarget),
                category = $el.attr("data-gacategory"),
                action = $el.attr("data-gaaction"),
                label = $el.attr("data-galabel");

            Analytics.trackEvent(category, action, label);
        });

        zE(function() {
            zE.setLocale("es");

            zE.identify({
                name: App.User.get("name"),
                email: App.User.get("email"),
                externalId: App.User.get("id")
            });

        });

        // ZenDesk contact form
        $(".js-show-contact").click(function() {
            zE.activate();
        });

        if ($.fn.lazyload) {
            $(".js-lazy-image").lazyload();
        }

        //i18n
        i18n.init({
            lng             : "es",
            resGetPath      : "/__lng__/locale/__ns__",
            getAsync        : false,
            fallbackLng     : "es",
            useCookie       : true,
            useLocalStorage : false,
            lowerCaseLng    : true,
            load            : "unspecific" // get only en, not en-US,
        });

    });
