define([
    "jquery",
    "underscore",
    "backbone",
    "utils/logger",
    ], function($, _, Backbone, Logger) {
        "use strict";

        return Backbone.Router.extend({

            routings: null,

            initialize: function() {
                this.routings = [];
                this.routings.push({ key: "default", path: /^.*$/, view: "commons/empty" });
                this.generateRoutes();
            },

            empty: function() {},

            createView: function(key, view, args) {
                Logger.log(key + " - " + "pages/" + view);

                if (view){
                    require([ "pages/" + view ], function(View) {

                        if (!App.Views[ "pages/" + view ]) {
                            var ViewHelper = (function() {
                                return function ViewHelper() {
                                    return View.apply(this, args);
                                };
                            })();
                            ViewHelper.prototype = View.prototype;

                            App.Views[ "pages/" + view ] = new ViewHelper(args);
                            App.Views[ "pages/" + view ].render();
                        }
                    });
                }
            },

            generateRoutes: function() {
                _.each(this.routings, function(r) {
                    r.path = new RegExp("(?:^|index.php\/)" + r.path.source);
                    this.route(r.path, r.key, function() { this.createView(r.key, r.view, arguments); }.bind(this));
                }, this);
            }

        });

    });
