define([
    "jquery",
    "underscore",
    "backbone",
    "utils/logger",
    ], function($, _, Backbone, Logger) {
        "use strict";

        return Backbone.Router.extend({

            routings: null,

            initialize: function() {
                this.routings = [];

                //Login
                this.routings.push({ key: "login", path: new RegExp(App.urlRegexTo("user/account/login") + ".*$"), view: "account/login" });

                //Account
                this.routings.push({ key: "accountRegister", path: new RegExp(App.urlRegexTo("user/account/register") + ".*$"), view: "account/register" });
                this.routings.push({ key: "accountEdit", path: new RegExp(App.urlRegexTo("user/account/edit") + ".*$"), view: "account/edit" });
                this.routings.push({ key: "accountConfirm", path: new RegExp(App.urlRegexTo("user/account/confirm") + ".*$"), view: "account/account-confirm" });

                // Shop
                this.routings.push({ key: "shopRegister", path: new RegExp(App.urlRegexTo("shop/register") + ".*$"), view: "shop/register" });
                this.routings.push({ key: "shopCheckout", path: new RegExp(App.urlRegexTo("shop/checkout") + ".*$"), view: "shop/checkout" });

                this.routings.push({ key: "shopPaymentOk", path: new RegExp(App.urlRegexTo("shop/paymentok") + ".*$"), view: "shop/paymentok" });
                this.routings.push({ key: "shopPaymentKo", path: new RegExp(App.urlRegexTo("shop/paymentko") + ".*$"), view: "shop/paymentko" });

                // Parking
                this.routings.push({ key: "parkingList", path: new RegExp(App.urlRegexTo("parkingdoor/parking/list") + ".*$") });
                this.routings.push({ key: "parkingEdit", path: new RegExp(App.urlRegexTo("parkingdoor/parking/edit") + ".*$"), view: "parking/edit" });
                this.routings.push({ key: "parkingEditSection", path: new RegExp(App.urlRegexTo("parkingdoor/parking/edit") + "/parking-lot$"), view: "parking/edit" });
                //ParkingLot
                this.routings.push({ key: "parkingLotEdit", path: new RegExp(App.urlRegexTo("parkingdoor/parking-lot/show") + ".*$"), view: "parking-lot/form" });
                this.routings.push({ key: "parkingLotEditInfo", path: new RegExp(App.urlRegexTo("parkingdoor/parking-lot/show") + "/info.*$"), view: "parking-lot/form" });
                this.routings.push({ key: "parkingLotPhotos", path: new RegExp(App.urlRegexTo("parkingdoor/parking-lot/show") + "/photos.*$") });
                this.routings.push({ key: "parkingLotOwners", path: new RegExp(App.urlRegexTo("parkingdoor/parking-lot/show-owners") + ".*$"), view: "parking-lot/owners" });
                this.routings.push({ key: "parkingLotEditInfo", path: new RegExp(App.urlRegexTo("parkingdoor/parking-lot/show") + "/rentals.*$"), view: "parking-lot/rentals" });
                this.routings.push({ key: "parkingLotRentals", path: new RegExp(App.urlRegexTo("parkingdoor/parking-lot/show-rentals") + ".*$"), view: "parking-lot/rentals" });
                this.routings.push({ key: "parkingLotAccess", path: new RegExp(App.urlRegexTo("parkingdoor/parking-lot/show-logs") + ".*$"), view: "parking-lot/show-logs" });

                // Remember password
                this.routings.push({ key: "requestPasswordReset", path: new RegExp(App.urlRegexTo("user/account/request-password-reset") + ".*$"), view: "account/request-password-reset" });

                this.routings.push({ key: "resetPassword", path: new RegExp(App.urlRegexTo("user/account/reset-password") + ".*$"), view: "account/reset-password" });

                //Empty routes
                this.routings.push({ key: "terms", path: new RegExp(App.urlRegexTo("site/terms") + ".*$") });
                this.routings.push({ key: "error", path: /error$/ });

                this.generateRoutes();
            },

            empty: function() {},

            createView: function(key, view, args) {
                view = view || "commons/empty";
                Logger.log(key + " - " + "views/" + view);

                if (view){
                    require([ "views/" + view ], function(View) {

                        if (!App.Views[ "views/" + view ]) {
                            var ViewHelper = (function() {
                                return function ViewHelper() {
                                    return View.apply(this, args);
                                };
                            })();
                            ViewHelper.prototype = View.prototype;

                            App.Views[ "views/" + view ] = new ViewHelper(args);
                            App.Views[ "views/" + view ].render();
                        }
                    });
                }
            },

            generateRoutes: function() {
                _.each(this.routings, function(r) {
                    r.path = new RegExp("(?:^|index.php\/)" + r.path.source);
                    this.route(r.path, r.key, function() { this.createView(r.key, r.view, arguments); }.bind(this));
                }, this);
            }

        });

    });